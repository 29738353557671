<template>
  <section id="dashboard-analytics">
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <b-row class="match-height">
        <b-col>
          <b-card class="cartas">
            <feather-icon
              size="30"
              icon="ActivityIcon"
            />
            <h2 class="m-1">
              {{ session }}
            </h2>
            <span>
              {{ $t('Sesiones totales') }}
            </span>
            <div v-if="sessionPercent != null">
              <feather-icon
                size="20"
                :class="sessionPercent.trend == 'u' ? 'text-success' : 'text-danger'"
                :icon="sessionPercent.trend == 'u' ? 'TrendingUpIcon' : 'TrendingDownIcon'"
              />
              <b :class="sessionPercent.trend == 'u' ? 'text-success' : 'text-danger'">
                {{ sessionPercent.change }}
              </b>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="cartas">
            <feather-icon
              size="30"
              icon="UsersIcon"
            />
            <h2 class="m-1">
              {{ total }}
            </h2>
            <span>
              {{ $t('totalUsers') }}
            </span>
            <div v-if="totalPercent != null">
              <feather-icon
                size="20"
                :class="totalPercent.trend == 'u' ? 'text-success' : 'text-danger'"
                :icon="totalPercent.trend == 'u' ? 'TrendingUpIcon' : 'TrendingDownIcon'"
              />
              <b :class="totalPercent.trend == 'u' ? 'text-success' : 'text-danger'">
                {{ totalPercent.change }}
              </b>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="cartas">
            <feather-icon
              size="30"
              icon="UserPlusIcon"
            />
            <h2 class="m-1">
              {{ newUser }}
            </h2>

            <span>
              {{ $t('Sesiones de usuario') }}
            </span>
            <div v-if="newUserPercent != null">
              <feather-icon
                size="20"
                :class="newUserPercent.trend == 'u' ? 'text-success' : 'text-danger'"
                :icon="newUserPercent.trend == 'u' ? 'TrendingUpIcon' : 'TrendingDownIcon'"
              />
              <b :class="newUserPercent.trend == 'u' ? 'text-success' : 'text-danger'">
                {{ newUserPercent.change }}
              </b>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="cartas">
            <feather-icon
              size="30"
              icon="UserCheckIcon"
            />
            <h2 class="m-1">
              {{ register }}
            </h2>

            <span>
              {{ $t("finalUsers.title") }}
            </span>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
  },
  props: {
    period: {
      default: '',
    },
    apiKey: {
      default: '',
    },
    appId: {
      default: '',
    },
    statisticsUrl: {
      default: '',
    },

  },
  data() {
    return {
      total: null,
      totalPercent: null,
      register: null,
      session: null,
      sessionPercent: null,
      newUser: null,
      newUserPercent: null,
      show: false,
    }
  },

  watch: {
    period() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.show = true
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }

      let period = ''

      if (Array.isArray(this.period)) {
        const inicio = this.period[0]
        const fin = this.period[1]

        period = `%5B${inicio}%2C${fin}%5D`
      } else {
        period = this.period
      }

      fetch(
        `${this.statisticsUrl}/o/analytics/dashboard?period=${period}&api_key=${this.apiKey}&app_id=${this.appId}&preventRequestAbort=true`,
        options,
      )
        .then(response => response.json())
        .then(response => {
          const datos = response[this.period].dashboard
          this.totalPercent = datos.total_users

          this.total = datos.total_users.total

          this.total = this.total
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

          this.session = datos.total_sessions.total
          this.sessionPercent = datos.total_sessions
          this.session = this.session
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          this.newUser = datos.new_users.total
          this.newUserPercent = datos.new_users

          this.newUser = this.newUser
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

          this.registerUsers()
        })
        .catch(() => { this.show = false })
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // Sumar 1 porque enero es 0
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

      return formattedDate
    },
    registerUsers() {
      let startDate = ''
      let endDate = ''
      if (Array.isArray(this.period)) {
        startDate = this.formatTimestamp(this.period[0])
        endDate = this.formatTimestamp(this.period[1])
      } else {
        const actual = new Date()
        const startOfDay = new Date(actual)
        let endOfDay = null
        let past = ''
        switch (this.period) {
          case 'hour':
            past = new Date().getTime() - (60 * 60 * 1000)
            endDate = this.formatTimestamp(actual.getTime())
            startDate = this.formatTimestamp(past)

            break
          case 'yesterday':
            startOfDay.setDate(startOfDay.getDate() - 1)
            startOfDay.setHours(0, 0, 0, 0)
            endOfDay = new Date(startOfDay)
            endOfDay.setHours(23, 59, 59, 999)
            endDate = this.formatTimestamp(endOfDay.getTime())
            startDate = this.formatTimestamp(startOfDay.getTime())
            break
          case '7days':
            startOfDay.setDate(startOfDay.getDate() - 7)
            startOfDay.setHours(0, 0, 0, 0)
            endOfDay = new Date(actual)
            endOfDay.setHours(0, 0, 0, 0)
            endDate = this.formatTimestamp(endOfDay.getTime())
            startDate = this.formatTimestamp(startOfDay.getTime())
            break
          case '30days':
            startOfDay.setDate(startOfDay.getDate() - 30)
            startOfDay.setHours(0, 0, 0, 0)
            endOfDay = new Date(actual)
            endOfDay.setHours(0, 0, 0, 0)
            endDate = this.formatTimestamp(endOfDay.getTime())
            startDate = this.formatTimestamp(startOfDay.getTime())
            break
          case '60days':
            startOfDay.setDate(startOfDay.getDate() - 60)
            startOfDay.setHours(0, 0, 0, 0)
            endOfDay = new Date(actual)
            endOfDay.setHours(0, 0, 0, 0)
            endDate = this.formatTimestamp(endOfDay.getTime())
            startDate = this.formatTimestamp(startOfDay.getTime())
            break

          default:
            break
        }
      }
      const userData = getUserData()
      const query = `
        query {
          allVusers(client:"${userData.profile.client.id}",startDate: "${startDate}", endDate: "${endDate}") {
            totalCount
          }
        }
      `
      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)

          this.register = res.data.data.allVusers.totalCount
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          this.show = false
        })
        .catch(error => {
          console.log(error)
          this.show = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#dashboard-analytics .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#dashboard-analytics .card-body h4 {
  font-size: 1.286rem !important;
}

.cartas {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align-last: center;
  border-radius: 20px 20px 20px 20px;
  border: 2px solid #7367f0;
}
</style>
